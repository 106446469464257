import React from "react";
import "./ItemSingle.scss";

export const CarouselItem = ({
  title,
  text,
  image,
  imageAltText
}: {
  title: string;
  text: string;
  image: string;
  imageAltText?: string;
}) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className="bg-primary card-circle">
        <img alt={imageAltText || ""} className="card-icon" src={image} />
      </div>
      <h3 className="text-primary mt-3">{title}</h3>
      <p className="px-5 text-center">{text}</p>
    </div>
  );
};
