import React from "react";
import { DarkToggle } from "./header/DarkToggle";
import { DownloadAppButtons } from "./header/DownloadAppButtons";
import { Headline } from "./header/Headline";
import { Mockup } from "./header/Mockup";
import { HeaderCurve } from "./shapes/HeaderCurve";
import { Logo } from "./shared/Logo";
import "./Header.scss";

export const Header = ({ children }: { children?: JSX.Element }) => {
  return (
    <div>
      <nav className="navbar bg-primary" style={{ alignContent: "flex-start" }}>
        <Logo />
        <div style={{ float: "right" }}>
          <DarkToggle />
        </div>
      </nav>
      <div className="row bg-primary mx-0">
        <div className="col-xs-12 col-lg-6 col-xl-5 px-0 my-lg-5">
          <Headline />
          <DownloadAppButtons />
        </div>
        <div className="col-xs-12 col-lg-6 col-xl-7 px-0">
          <Mockup />
        </div>
      </div>
      <div className="bg-primary" style={{ height: 50 }} />
      <div className="bg-light">
        <div className="curve-container">
          <HeaderCurve />
        </div>
        {children}
      </div>
    </div>
  );
};
