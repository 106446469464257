import React from "react";
import Image from "../models/Image";
import { imagesLight, imagesDark } from "../utils/mockups";

const selectedImage = imagesLight[0];

export default React.createContext({
  isThemeDark: false,
  setIsThemeDark: (value: boolean) => {},
  imagesLight,
  imagesDark,
  selectedImage,
  setSelectedImage: (value: Image) => {}
});
