import React from "react";
import Medidas from "../assets/medidas.svg";
import Exames from "../assets/laboratorio.svg";
import Historico from "../assets/historico-saude.svg";
import { Card, Side } from "./card/Card";

export const Cards = () => {
  return (
    <div>
      <Card
        title="Monitore suas Medidas"
        text="Com o aplicativo, você pode registrar e observar seus sinais vitais. Com seu médico também podendo analisá-los remotamente."
        image={Medidas}
        side={Side.LEFT}
      />
      <Card
        title="Exame de Laboratório e Imagem"
        text="Digitalize ou tire fotos dos seus exames solicitados, assim seu médico poderá ter acesso na sua próxima consulta."
        image={Exames}
        side={Side.RIGHT}
      />
      <Card
        title="Sua Saúde em Suas Mãos"
        text="Você tem acesso à seus dados clínicos e histórico de saúde. Melhorando o atendimento e o diagnóstico médico."
        image={Historico}
        side={Side.LEFT}
      />
    </div>
  );
};
