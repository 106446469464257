import React from "react";

export const FooterCurve = () => {
  return (
    <svg
      viewBox="0 0 165 42"
      className="svg"
      preserveAspectRatio="none"
      style={{ maxHeight: 100, width: "100%" }}
    >
      <path d="M 165,42 C 165,42 28,42 0,0 V 43 Z" fill="#0cb7b7" />
    </svg>
  );
};
