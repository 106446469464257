import React from "react";
import "./ItemMultiple.scss";

export const CarouselItemMultiple = ({
  parceiros
}: {
  parceiros: Array<string[]>;
}) => {
  return (
    <div className="container-fluid">
      {parceiros.map((row, idx) => {
        return (
          <div key={`row${idx}`} className="row px-4">
            {row.map((item, idx) => {
              return (
                <div
                  key={`item${idx}`}
                  className="col m-1 py-4 m-sm-2 m-md-3 py-md-5 m-lg-4 parceiro-block border-light"
                >
                  <div
                    style={{
                      backgroundImage: "url(" + item + ")",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "contain"
                    }}
                    className="parceiro-item"
                  />
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
