import React from "react";
import { FooterCurve } from "./shapes/FooterCurve";
import { Logo } from "./shared/Logo";

export const Footer = () => {
  return (
    <footer className="text-white-50">
      <div>
        <FooterCurve />
      </div>
      <div className="text-center py-4 text-light bg-primary">
        <div className="container">
          <Logo style={{ width: 180, marginBottom: 20 }} />
          <h4 className="font-weight-bold">Fale com a gente</h4>
          <a href="mailto:contato@prontlife.com.br" className="text-light">
            contato@prontlife.com.br
          </a>
          <p className="mt-4">
            Ao acessar o ProntLife, você concorda com os nossos{" "}
            <a
              className="font-weight-bold text-light"
              href="https://prontlife.com.br/terms/termos_de_uso_prontlife.pdf"
            >
              Termos de Uso
            </a>{" "}
            e com a nossa{" "}
            <a
              className="font-weight-bold text-light"
              href="https://prontlife.com.br/privacy/politica_de_privacidade_seguranca_e_uso_de_dados_prontLife.pdf"
            >
              Política de Privacidade
            </a>
            ,{" "}
            <a
              className="font-weight-bold text-light"
              href="https://prontlife.com.br/privacy/politica_de_privacidade_seguranca_e_uso_de_dados_prontLife.pdf"
            >
              Segurança e Uso de Dados
            </a>
            .
          </p>
        </div>
      </div>
    </footer>
  );
};
