import React, { useContext } from "react";
import { Carousel } from "./carousel/Carousel";
import { CarouselItem } from "./carousel/ItemSingle";
import Carteirinha from "../assets/cart.svg";
import Calendario from "../assets/calendario.svg";
import Medicamentos from "../assets/remedios.svg";
import ThemeContext from "../context/themeContext";
import Image from "../models/Image";

function switchImage(
  index: number,
  imagesLight: Image[],
  imagesDark: Image[],
  isThemeDark: boolean,
  setSelectedImage: (value: Image) => void
) {
  if (isThemeDark) {
    const selectedImage = imagesDark.find(image => image.id === index);
    if (selectedImage) {
      setSelectedImage(selectedImage);
    }
  } else {
    const selectedImage = imagesLight.find(image => image.id === index);
    if (selectedImage) {
      setSelectedImage(selectedImage);
    }
  }
}

export const CarouselFeatures = () => {
  const { imagesDark, imagesLight, isThemeDark, setSelectedImage } = useContext(
    ThemeContext
  );

  return (
    <Carousel
      settings={{
        autoplay: true,
        autoplaySpeed: 6000,
        afterChange: index =>
          switchImage(
            index,
            imagesLight,
            imagesDark,
            isThemeDark,
            setSelectedImage
          )
      }}
      className=" pb-5"
    >
      <CarouselItem
        title="Carteirinha Online"
        text="Tenha acesso à sua carteirinha de saúde virtual em qualquer lugar,
garantindo atendimento sem burocracia."
        image={Carteirinha}
      />
      <CarouselItem
        title="Agendamento Online"
        text="Agendamento rápido e fácil pelo Aplicativo. Procurando por profissionais ou por horários disponíveis próximos à você."
        image={Calendario}
      />
      <CarouselItem
        title="Medicamentos"
        text="Acesso total à sua prescrição e os medicamentos que são lembrados na hora de tomá-los."
        image={Medicamentos}
      />
    </Carousel>
  );
};
