import { faLightbulb as farLightbulb } from "@fortawesome/free-regular-svg-icons";
import { faLightbulb as faslightbulb } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect } from "react";
import Toggle from "react-toggle";
import ThemeContext from "../../context/themeContext";
import ImageInterface from "../../models/Image";

function switchDark(
  dark: boolean,
  setIsThemeDark: (value: boolean) => void,
  imagesLight: ImageInterface[],
  imagesDark: ImageInterface[],
  selectedImage: ImageInterface,
  setSelectedImage: (image: ImageInterface) => void
) {
  const root = document.getElementsByTagName("html")[0];
  if (dark) {
    const newImage = imagesLight.find((image) => image.id === selectedImage.id);
    if (newImage) {
      setSelectedImage(newImage);
    }
    setIsThemeDark(false);
    root.setAttribute("class", "");
  } else {
    const newImage = imagesDark.find((image) => image.id === selectedImage.id);
    if (newImage) {
      setSelectedImage(newImage);
    }
    setIsThemeDark(true);
    root.setAttribute("class", "dark");
  }
}

export const DarkToggle = () => {
  const {
    setIsThemeDark,
    imagesLight,
    imagesDark,
    selectedImage,
    setSelectedImage,
  } = useContext(ThemeContext);
  // Preload mocks
  useEffect(() => {
    const preload = new Image();
    preload.src = selectedImage.image;
  }, [selectedImage.image]);

  return (
    <label>
      <Toggle
        defaultChecked={true}
        icons={{
          checked: <FontAwesomeIcon color="#ffc107" icon={faslightbulb} />,
          unchecked: <FontAwesomeIcon color="#ffffff" icon={farLightbulb} />,
        }}
        onChange={(event: any) => {
          switchDark(
            event.target.checked,
            setIsThemeDark,
            imagesLight,
            imagesDark,
            selectedImage,
            setSelectedImage
          );
        }}
      />
    </label>
  );
};
