import React from "react";
import "./Headline.scss";

export const Headline = () => {
  return (
    <div
      style={{ paddingBottom: 20 }}
      className="bg-primary headline text-light"
    >
      <h1>Aplicativo do Paciente</h1>
      <h2>
        O <strong>ProntLife Paciente</strong> é a nova ferramenta para auxiliar
        no controle de sua saúde.
      </h2>
    </div>
  );
};
