import React from "react";
import LogoImg from "../../assets/logo.svg";

export const Logo = ({
  style = { width: 120 }
}: {
  style?: React.CSSProperties;
}) => {
  return <img src={LogoImg} alt="ProntLife" style={style} />;
};
