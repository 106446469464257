import MockupCarteirinhaLight from "../assets/mockups/light/mockup-carteirinha-light.png";
import MockupConsultaLight from "../assets/mockups/light/mockup-consulta-light.png";
import MockupRemediosLight from "../assets/mockups/light/mockup-remedios-light.png";
import MockupCarteirinhaDark from "../assets/mockups/dark/mockup-carteirinha-dark.png";
import MockupConsultaDark from "../assets/mockups/dark/mockup-consulta-dark.png";
import MockupRemediosDark from "../assets/mockups/dark/mockup-remedios-dark.png";
import Image from "../models/Image";

export const imagesLight: Image[] = [
  { id: 0, image: MockupCarteirinhaLight, alt: "Mockup Carteirinha" },
  { id: 1, image: MockupConsultaLight, alt: "Mockup Consulta" },
  { id: 2, image: MockupRemediosLight, alt: "Mockup Remedios" }
];

export const imagesDark: Image[] = [
  { id: 0, image: MockupCarteirinhaDark, alt: "Mockup Carteirinha" },
  { id: 1, image: MockupConsultaDark, alt: "Mockup Consulta" },
  { id: 2, image: MockupRemediosDark, alt: "Mockup Remedios" }
];
