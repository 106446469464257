import React from "react";

export enum Side {
  LEFT = "left",
  RIGHT = "right"
}
export const Card = ({
  title,
  text,
  image,
  side
}: {
  title: string;
  text: string;
  image: string;
  side: Side;
}) => {
  const imageComp = (
    <div className="col-5 align-self-center">
      <img alt={title} src={image} />
    </div>
  );
  const textComp = (
    <div className="col-7 d-flex flex-column justify-content-center">
      <h4 className="text-primary">{title}</h4>
      <p className="text-left text-muted">{text}</p>
    </div>
  );
  return (
    <div className="container py-5">
      <div className="row">
        {side === Side.LEFT ? imageComp : textComp}
        {side === Side.LEFT ? textComp : imageComp}
      </div>
    </div>
  );
};
