import React from "react";
import Amazon from "../assets/parceiros/amazon.svg";
import Camarj from "../assets/parceiros/camarj.svg";
import Camperj from "../assets/parceiros/camperj.svg";
import Coppe from "../assets/parceiros/coppe.svg";
import Crc from "../assets/parceiros/crc.png";
import Fbstart from "../assets/parceiros/fbstart.png";
import Gama from "../assets/parceiros/gama.svg";
import Inovativa from "../assets/parceiros/inovativabrasil.svg";
import LabFuturo from "../assets/parceiros/labfuturo.png";
import Lemobs from "../assets/parceiros/lemobs.svg";
import Lncc from "../assets/parceiros/lncc.svg";
import Policlinica from "../assets/parceiros/policlinica.png";
import QSaude from "../assets/parceiros/qsaude.svg";
import Qualicorp from "../assets/parceiros/qualicorp.svg";
import Sebrae from "../assets/parceiros/sebrae.svg";
import Sigelu from "../assets/parceiros/sigelu.svg";
import Surgbook from "../assets/parceiros/surgbook.png";
import Viva from "../assets/parceiros/viva.svg";
import { Carousel } from "./carousel/Carousel";
import { CarouselItemMultiple } from "./carousel/ItemMultiple";

export const CarouselParceiros = () => {
  return (
    <>
      <h3 className="text-center text-dark font-weight-bold my-4">Parceiros</h3>
      <Carousel settings={{ autoplay: true, autoplaySpeed: 3000 }}>
        <CarouselItemMultiple
          parceiros={[
            [Gama, Viva],
            [Camperj, Camarj]
          ]}
        />
        <CarouselItemMultiple
          parceiros={[
            [Crc, Policlinica],
            [Coppe, Lncc]
          ]}
        />
        <CarouselItemMultiple
          parceiros={[
            [Sigelu, Lemobs],
            [Qualicorp, QSaude]
          ]}
        />
        <CarouselItemMultiple
          parceiros={[
            [LabFuturo, Sebrae],
            [Surgbook, Fbstart]
          ]}
        />
        <CarouselItemMultiple parceiros={[[Amazon, Inovativa]]} />
      </Carousel>
    </>
  );
};
